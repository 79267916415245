

















import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import Qrcode from "@/components/Qrcode.vue"
import { env } from "@/env"
import ky from "ky"

export default defineComponent({
    name: "AppDownload",
    components: {
        Qrcode,
    },
    setup() {
        const state = reactive({
            version: "",
            message: "",
        })

        async function loadData() {
            const result = await ky.get(`${env.APP_RELEASE}/version/`).json<{
                latest: string
                message: string
            }>()
            state.version = result.latest
            state.message = result.message
        }

        loadData()

        return {
            ...toRefs(state),
            env,
        }
    },
})
